import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";

import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "14px",
    backgroundColor: theme.palette.grey[50],
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "155px",
    marginRight: theme.spacing(1),
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));
interface FilterToolbarProps {
  // className: string;
  areaClicked: any;
  prevAreaClicked:any
  defaultCategory: string;
  areasList: any;
}

function FilterToolbar({
  areaClicked,
  prevAreaClicked,
  defaultCategory,
  areasList,
}: FilterToolbarProps) {
  const classes = useStyles();
  const [selectedArea, setSelectedArea] = useState(defaultCategory);

  const getIcon = (param: any) => {
    switch (param) {
      case "PeopleOutlineIcon":
        return (
          <PeopleOutlineIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "MonetizationOnOutlinedIcon":
        return (
          <MonetizationOnOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "BuildOutlinedIcon":
        return (
          <BuildOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "SettingsApplicationsOutlinedIcon":
        return (
          <SettingsApplicationsOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "DateRangeOutlinedIcon":
        return (
          <DateRangeOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "LocalShippingOutlinedIcon":
        return (
          <LocalShippingOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "LocalHospitalOutlinedIcon":
        return (
          <LocalHospitalOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      case "ReportProblemOutlinedIcon":
        return (
          <ReportProblemOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
      default:
        return (
          <WorkOutlineOutlinedIcon
            style={{ fontSize: 20, marginRight: 10, marginLeft: 10 }}
          />
        );
    }
  };

  const handleAreaClicked = async (area: any) => {
    if (area === selectedArea) {
      await setSelectedArea("");
      areaClicked("");
      prevAreaClicked(area);
    } else {
      await setSelectedArea(area);
      areaClicked(area);
      prevAreaClicked("");
    }
  };

  return (
    <div>
      <Box mt={3}>
        <Typography color="textSecondary" variant="body1">
          Filter dashboards by area
        </Typography>
        <Card>
          <CardContent>
            <Grid container spacing={1} direction="row" justifyContent="center">
              {areasList?.map((area: any, i: any) => {
                const selected = area.resourceCategoryName === selectedArea;
                return (
                  <Grid item key={i}>
                    <Button
                      size="small"
                      variant="contained"
                      className={
                        classes.button + " " + (selected && classes.active)
                      }
                      onClick={() =>
                        handleAreaClicked(area.resourceCategoryName)
                      }
                    >
                      {getIcon(area.resourceCategoryIcon)}
                      {area.resourceCategoryName}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

FilterToolbar.propTypes = {
  className: PropTypes.string,
};

export default FilterToolbar;
