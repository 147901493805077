import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper, makeStyles, Toolbar } from "@material-ui/core";
import ConfirmDialog from "src/components/utils/ConfirmDialog";
import Notification from "src/components/utils/Notification";
import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import { getAdminIconName } from "../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
}));

export const UpdatePage = (props) => {
  const {
    title,
    subtitle,
    notify,
    setNotify,
    confirmDialog,
    setConfirmDialog,
    children,
    disableBackButton,
  } = props;
  const classes = useStyles();

  return (
    <Page title={title}>
      <PageHeader
        title={title}
        subtitle={subtitle}
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        disableBackButton={disableBackButton}
      />

      <Paper className={classes.pageContent}>{children}</Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Page>
  );
};
