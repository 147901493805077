import {
  Paper,
  Grid,
  CircularProgress,
  makeStyles,
  Toolbar,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import {useEffect, useState} from "react";
import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import Palette from "@material-ui/icons/Palette";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import ColorPicker  from './ColorPicker';
import { Theme } from "./Theme";
import MaterialButton from "src/components/utils/Button";
import { getAutorenewedToken } from "../../auth/msalUtils";
import Notification from "../../utils/Notification";
import rgbConverter from "../../../utils/rgbConverter"
import PreviewMenu from "./PreviewMenu";

const MENU_COLOR_SESSION_STORAGE_KEY = 'MENU_COLOR';
const TEXT_COLOR_SESSION_STORAGE_KEY = 'TEXT_COLOR';


const useStyles = makeStyles((theme) => ({

  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    right: "10px",
    fontSize: "14px",
  },
  flex:{
    display:"flex",
  },
  toolBar:{
    flexDirection:"row-reverse"
  },
 space:{
  justifyContent:"space-between"
 },
  hover: {
    "&:hover": {
      backgroundColor: "#e8e9eb",
      cursor: "pointer",
    },
    "&:click": {
      borderColor: theme.palette.text.primary,
    },
    flexDirection:'column'
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 18,
  },
  labeltext:{
    margin:'auto',
    minWidth : 150
  },
  previewLabel:{
    color: "#86BC25"
  },
  preview:{
    minWidth:400,
    margin: 'auto',
    marginLeft:'10%'
  }
 
}));



export default function UpdatePortalTheme()
{
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState<Theme | null>([]);
  const [open, setOpen] = useState(true);
  const [barOpen, setBarOpen] = useState(true);
  const [hover, setHover] = useState(rgbConverter.getHover());
  const [selection, setSelection] = useState(rgbConverter.getSelection());
  const [text, setText] = useState(window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY));
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getTheme = async () => {
    fetch(process.env.REACT_APP_API_PATH + "/theme", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => 
    {
      
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const data = await response.json();
     
      setTheme(data);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
    });
  } 
  

  function handleColorChange(color:any) {
    if(color.category == "Menu"){
      setHover(rgbConverter.getHover(color.colorHexCode))
      setSelection(rgbConverter.getSelection(color.colorHexCode))
    }
    else{
      setText(color.colorHexCode)
    }
      const newList = theme.map((item) => {
        if (item.themeId === color.themeId) {
          const updatedItem = {
            ...item,
            colorHexCode: color.colorHexCode,
          };
          return updatedItem;
        }

        return item;
      });
      setTheme(newList);
  }

  const setLocalStorage =(restoreDefault:boolean) => {
    theme?.map((item) => {
      var color = restoreDefault ? item.defaultColorHexCode : item.colorHexCode
      if(item.category == "Menu")
        window.sessionStorage.setItem(MENU_COLOR_SESSION_STORAGE_KEY,color);
      else if (item.category == "Text")
        window.sessionStorage.setItem(TEXT_COLOR_SESSION_STORAGE_KEY,color);
    });
  }

  const updateTheme = async () => {
    setLoading(true)
    fetch(process.env.REACT_APP_API_PATH + "/theme", {
      method: "POST",
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },

      body: JSON.stringify(theme)
    }).then(async (response) => {

      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        setNotify({
          isOpen: true,
          message: 'Failed to update theme',
          type: "error",
        });
        return Promise.reject(error);
      }
      const data = await response.json();

      setTheme(data);
      setLocalStorage(false);
      setLoading(false);
      setNotify({
        isOpen: true,
        message: 'Theme has been updated. Please refresh the page to view changes.',
        type: "success",
      });

    }).catch((error) => {
      setNotify({
        isOpen: true,
        message: 'Failed to update theme',
        type: "error",
      });
        setLoading(false);
    });
  }

  const resetDefaultTheme = async () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_PATH + "/theme/resetTheme", {
      method: "POST",
      headers: {authorization: await getAutorenewedToken() },
    }).then(async (response) => {

      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        setNotify({
          isOpen: true,
          message: 'Failed to restore default theme',
          type: "error",
        });
        return Promise.reject(error);
      }
      const data = await response.json();

      setTheme(data);
      setLocalStorage(true);

      setHover(rgbConverter.getHover(window.sessionStorage.getItem(MENU_COLOR_SESSION_STORAGE_KEY)))
      setSelection(rgbConverter.getSelection(window.sessionStorage.getItem(MENU_COLOR_SESSION_STORAGE_KEY)))
      setText(window.sessionStorage.getItem(TEXT_COLOR_SESSION_STORAGE_KEY))

      setLoading(false);
      //refreshPage();
      setNotify({
        isOpen: true,
        message: 'Restored to default theme. Please refresh the page to view changes.',
        type: "success",
      });
    }).catch((error) => {
      setNotify({
        isOpen: true,
        message: 'Failed to restore default theme',
        type: "error",
      });
        setLoading(false);
    });
  }

  const handleClick = () => {
    setOpen((prev) => !prev);

    if (!barOpen) {
     
    }
  };


  useEffect(() => {
    getTheme();
  }, []); 
   

  return (
    <Page title="Update Portal Theme">
      <PageHeader
        title="Update Portal Theme"
        subtitle="Customize portal Theme"
        icon={<Palette />}
        disableBackButton={false}
      />
      <Notification notify={notify} setNotify={setNotify} />

      <Paper className={classes.pageContent}>
        <Toolbar className={classes.toolBar}>
          <MaterialButton
            text="Save"
            variant="outlined"
            startIcon={<SaveIcon />}
            className={classes.newButton}
            onClick={() => {
              updateTheme();
            }}
          />
          <MaterialButton
            text="Reset Default Theme"
            variant="outlined"
            startIcon={<RefreshIcon />}
            className={classes.newButton}
            onClick={() => {
              resetDefaultTheme();
            }}
          />
        </Toolbar>
        {loading && (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={0}
          >
            <CircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
          <List>
            {theme.map((res) =>
              <ListItem key={res.themeId} className={classes.space}>
                <div className={classes.flex} >
                  <Typography color="textPrimary" className={classes.labeltext} display="inline">Select {res.category} Color</Typography>
                  <ColorPicker  theme={res} themeSelected={(color) => handleColorChange(color)} ></ColorPicker>
                </div>
                <div className={classes.preview}>
                  {res.category == "Menu" ? (
                    <>
                      <Typography color="textPrimary" variant="h6" className={classes.previewLabel} display="inline">Preview</Typography>
                      <PreviewMenu hover={hover} selection={selection} text={text}></PreviewMenu>
                    </>) : 
                  (<span style={{color: text}} >This is a sample text</span>)
                }
                </div>  
                
              </ListItem>
            )}
          </List>
          </>
        )}
      </Paper>
    </Page>

  );
}