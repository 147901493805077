import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import Page from "src/components/utils/Page";
import ChatInput from "./ChatInput";
import { ChatMessage, GPTMessage } from "./ChatMessage";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../auth/authConfig";
import ChatTopBar from "./ChatTopBar";
import "./ChatView.css";
import ChatBox from "./ChatBox";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
type ChatViewProps = {
  closeSlide: (value: boolean) => void;
}
function ChatView(props: ChatViewProps) {
  // create chat history state for typescript
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const { instance, accounts, inProgress } = useMsal();
  const [initials, setInitials] = useState<string>("?");
  const messageInputRef = useRef<HTMLInputElement | null>(null);
  const apiScope = msalConfig.auth.clientId + "/.default";
  const [devMode, setDevMode] = useState(false);

  useEffect(() => {
    if (
      chatHistory.length >= 1 &&
      chatHistory[chatHistory.length - 1].role === "user"
    ) {
      const getResponse = async () => {
        const lastMsg = chatHistory[chatHistory.length - 1].message;
        const jsonData = { prompt: lastMsg };
        const message: GPTMessage = {
          message: "",
          role: "llm",
          name: "IMCP ASSIST",
          tokens: 0,
          price: 0,
          date: new Date()
        };
        setChatHistory([...chatHistory, message]);
        try {
          const apiScope = msalConfig.auth.clientId + "/.default";
          const accessTokenRequest = {
            scopes: [`${apiScope}`],
            account: accounts[0],
          };
          const token = await instance.acquireTokenSilent(accessTokenRequest);
          const url: string | undefined = process.env.REACT_APP_AI_BACKEND_URL;
          if (url === undefined) {
            throw new Error("API URL is not defined");
          }
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token.accessToken,
            },
            body: JSON.stringify(jsonData),
          });
          if (!response.ok) {
            throw new Error();
          } else {
            const data = await response.json();
            const message: GPTMessage = {
              message: data.response,
              role: "llm",
              name: "IMCP ASSIST",
              tokens: data.tokens,
              price: data.price,
              date: new Date()
            };
            setChatHistory([...chatHistory, message]);
          }
        } catch {
          const message: GPTMessage = {
            message: "IMCP ASSIST had an error. Try again, or rephrase your question.",
            role: "llm",
            name: "IMCP ASSIST",
            tokens: 0,
            price: 0,
          };
          setChatHistory([...chatHistory, message]);
        }
      };

      getResponse();
    }
  }, [chatHistory]);

  const handleEnterMessage = async (e: KeyboardEvent) => {
    const lastChat = chatHistory[chatHistory.length - 1];
    if (e.key === "Enter" && !e.shiftKey) {
      if (!lastChat || lastChat.role !== "user") {
        // If user can send a message, add to chatHistory
        if (messageInputRef.current) {
          const input = messageInputRef.current.value;
          const newMessage = {
            message: input,
            role: "user",
            name: initials,
          };
          setChatHistory([...chatHistory, newMessage]);
          messageInputRef.current.value = "";
          e.preventDefault(); // prevent new line on enter
        }
      }
    }
  };

  useEffect(() => {
    if (accounts && accounts.length > 0 && accounts[0]) {
      const initials = accounts[0].name
        .split(", ")
        .map((name) => name[0])
        .join("");
      setInitials(initials);
    }
  }, [accounts]);

  function toggleDevMode() {
    setDevMode(!devMode);
  }
  const handleSlideClose = () => {
    props.closeSlide();
};

  return (
    <Page title="Chat">
      <ChatTopBar toggleDevMode={toggleDevMode} closeSlide={handleSlideClose}/>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <ChatBox chatHistory={chatHistory} devMode={devMode} />
      </Box>
      <Box
          sx={{
            paddingLeft: "2%",
            paddingRight: "2%",
          }}
        >
          <ChatInput
            handleEnterMessage={handleEnterMessage}
            messageInputRef={messageInputRef}
          />
        </Box>
    </Page>
  );
}
export default ChatView;
