import { Button, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import { getAutorenewedToken } from '../auth/msalUtils';
import CustomizedSteppers from './CustomiseStepper';
import MaterialButton from 'src/components/utils/Button';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/BlockOutlined';

const useStyles = makeStyles((theme) => ({
  bottomSpace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "2%",
    marginBottom: "2%"
  },
  fabProgress: {
    color: theme.palette.primary.main,
    marginTop: "10%",
    marginBottom: "10%",
  },
  progress: {
    color: theme.palette.primary.main,
    alignItems:"center",
    display:"flex",
    margin:"auto"
  },
  newButton: {
    marginRight: '10px',
    fontSize: '14px',
    float: 'right'
  }
}))
interface RequestWrapper {
  connectionDetails: any,
  connectionProperty: any
}

interface FormData {
  name: string,
  label: string
}


interface ConfigurationDetail {
  name: string,
  label: string
}
interface AuthenticationType {
  id: number,
  authenticationType: string,
  authenticationConfigDetails: AuthenticationConfigDetail[]
}

interface AuthenticationConfigDetail {
  id: number,
  name: string,
  label: string
}

interface LinkedServiceConfiguration {
  configurationDetails: ConfigurationDetail[],
  authenticationTypeRequired: boolean,
  authenticationType: AuthenticationType[]
}
interface ConnectionFormProps {
  id: number
  type: string
  nextStep: any
  linkedServiceId: any
}

const ConnectionForm = (props: ConnectionFormProps) => {
  const classes = useStyles();

  const [formValues, setFormValues] = useState({});
  const [formData, setFormData] = useState<FormData[]>([]);
  const [authFormData, setAuthFormData] = useState<FormData[]>([]);
  const [authFormValues, setAuthformValues] = useState({});
  const [authFormDataProperty, setAuthFormDataPropert] = useState<string[]>([]);
  const [linkedServiceConfig, setLinkedServiceConfig] = useState<LinkedServiceConfiguration | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingKeyValue, setLoadingKeyValue] = useState(true);
  const [testingConn, setTestingConn] = useState(false);
  const [saveingConn, setSaveingConn] = useState(false);
  const [saveingFailed, setSaveingFailed] = useState(false);
  const [addingSecret, setAddingSecret] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [connectionSuccessfull, setConnectionSuccessfull] = useState(false);
  const [frequency, setFrequency] = useState<string>('Monthly');
  const [linkedServiceId, setLinkedServiceId] = useState<string>();
  const [connectionProperty, setConnectionProperty] = useState<string[]>([]);
  const [requestWrapper, setRequestWrapper] = useState<RequestWrapper>({ connectionDetails: null, connectionProperty: null });
  const [selectedAuthType, setSelectedAuthType] = useState<string | null>(null);
  const [connectionFailedLog, setConnectionFailedLog] = useState<string | null>(null);
  const [connSaveFailedLog, setConnSaveFailedLog] = useState<string | null>(null);
  const [allSecrets, setAllSecrets] = useState({});

  const connectionType = props.type.replace(/\s+/g, '').toLowerCase()

  const handleChange = (event) => {
    setFrequency(event.target.value as string);
    setFormValues({
      ...formValues,
      ['frequency']: event.target.value,
    });
  };

  const handleChangeAuthType = (event) => {

    var authType = linkedServiceConfig.authenticationType.find(x => x.authenticationType == event.target.value)
    var authProp = authType.authenticationConfigDetails.map(x => x.name);
    const emptyObject = createEmptyObject(authProp);

    setAuthFormData(authType.authenticationConfigDetails);
    setAuthformValues(emptyObject);
    setAuthFormDataPropert(authProp);
    setSelectedAuthType(event.target.value);
    setFormValues({
      ...formValues,
      ['authenticationType']: event.target.value
    });

  };

  const handleInputChange = (fieldName) => (e) => {
    if (selectedAuthType) {
      setFormValues({
        ...formValues,
        [fieldName]: e.target.value,
        ['frequency']: frequency,
        ['authenticationType']: selectedAuthType
      });
    }
    else {
      setFormValues({
        ...formValues,
        [fieldName]: e.target.value,
        ['frequency']: frequency,
      });
    }
  };

  const handleAuthDetailsInputChange = (fieldName) => (e) => {
    setAuthformValues({
      ...authFormValues,
      [fieldName]: e.target.value,
    });
  };

  const getConfigurationDetails = async () => {

    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/configuration-detail?id=" + props.id, {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
      const data = await response.json();
      //setLoading(false);
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      setLinkedServiceConfig(data)
      //setFormData(data.configurationDetails);

    }).catch((error) => {
      //setLoading(false);
    });
  }
  const handleSaveConnection = async () => {
    if(connectionType == 'restapi')
    {
      await triggerDataPiplineForRestApi();
    }
    else
    {
      await triggerDataPipline();
    }
  }

  const triggerDataPipline = async () => {
    setSaveingConn(true)
    setSaveingFailed(false)
    fetch(process.env.REACT_APP_DATA_PIPLINE + "", {
      method: "POST",
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },
      body: JSON.stringify(
        {
          prompt: props.type.replace(/\s+/g, '').toLowerCase()
        })

    }).then(async (response) => {
      const data = await response.json();
      setLoading(false);
      if (!response.ok) {
        // get error message from body or default to response statusText
        setSaveingFailed(true)
        setSaveingConn(false)
        setConnSaveFailedLog(data.detail)
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      if(data.status != undefined && data.status == 500)
      {
        setSaveingConn(false)
        setSaveingFailed(true)
        setConnSaveFailedLog(data.detail)
      }
      else
      {
        setSaveingConn(false)
        props.nextStep(2)
        props.linkedServiceId(linkedServiceId)
      }
      setSaveingConn(false)
    }).catch((error) => {
      setSaveingConn(false)
      setSaveingFailed(true)
      setLoading(false);
    });
  }
  const  allSecretsGetAllSecrets = async () =>{
    setLoadingKeyValue(true);
    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/get-allvalues/"+connectionType, {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
      const data = await response.json();
      setLoadingKeyValue(false);
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      setAllSecrets(data);
      isEmptyObject(data) ? setDisableEdit(false) : setDisableEdit(true)
    }).catch((error) => {
      setLoadingKeyValue(false);
    });
  }

  const triggerDataPiplineForRestApi = async () => {
    setSaveingConn(true)
    setSaveingFailed(false)
    fetch(process.env.REACT_APP_DATA_PIPLINE_REST_API + "", {
      method: "POST",
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },
      body: JSON.stringify(
        {
          sourceURL:
          {
            prompt: formValues["sourceurl"] ? formValues["sourceurl"]: ""
          },
          Username:{
            prompt: authFormValues["userName"] ? authFormValues["userName"] : ""
          },
          Password:{
            prompt:authFormValues["password"] ? authFormValues["password"] : ""
          }
        })

    }).then(async (response) => {
      const data = await response.json();
      setLoading(false);
      if (!response.ok) {
        // get error message from body or default to response statusText
        setSaveingFailed(true)
        setSaveingConn(false)
        setConnSaveFailedLog(data.detail)
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      if(data.status != undefined && data.status == 500)
      {
        setSaveingConn(false)
        setSaveingFailed(true)
        setConnSaveFailedLog(data.detail)
      }
      else
      {
        setSaveingConn(false)
       props.nextStep(2)
       props.linkedServiceId(linkedServiceId)
      }
    }).catch((error) => {
      setSaveingConn(false)
      setSaveingFailed(true)
      setLoading(false);
    });
  }


  function areAllPropertiesFilled(obj: Record<string, any>): boolean {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === '') {
        return true;
      }
    }
    return false;
  }

  const executeTerraform = async () => {
    setTestingConn(true)
    fetch(process.env.REACT_APP_TERRAFORM_API + "", {
      method: "POST",
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },
      body: JSON.stringify(
        {
          prompt: props.type.replace(/\s+/g, '').toLowerCase()
        })

    }).then(async (response) => {
      const data = await response.json();
      setLoading(false);
      if (!response.ok) {
        // get error message from body or default to response statusText
        setConnectionFailed(true)
        setConnectionFailedLog(data.detail)
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      setTestingConn(false)
      if (data.test_connection_result) {
        setConnectionSuccessfull(true)
        setLinkedServiceId(data.linked_service_id)
        setDisableEdit(true)
      }
      if (!data.test_connection_result) {
        setConnectionSuccessfull(false)
        setConnectionFailed(true)
        setConnectionFailedLog(data.detail)
      }
    }).catch((error) => {
      setTestingConn(false)
      setConnectionFailed(true)
      setLoading(false);
    });
  }

  const addSecrets = async (property: any, values: any) => {
    setAddingSecret(true)
    setConnectionFailed(false)
    setConnectionSuccessfull(false);
    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/process-json", {
      method: "POST",
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json',
        authorization: await getAutorenewedToken()
      },
      body: JSON.stringify(
        {
          connectionProperty: property,
          connectionDetails: values,
          connectionType: props.type
        })

    }).then(async (response) => {
      const data = await response.json();
      setLoading(false);
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        setConnectionFailed(true)
        setConnectionFailedLog(error)
        return Promise.reject(error);
      }
      else {
        await executeTerraform();
      }
      setAddingSecret(false)
    }).catch((error) => {
      setAddingSecret(false)
      setConnectionFailed(true)
      setLoading(false);
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    //await addSecrets(connectionProperty, formValues);
    if (linkedServiceConfig?.authenticationTypeRequired && selectedAuthType) {
      const combinedvalues = { ...authFormValues, ...formValues };
      const combinedProperty = authFormDataProperty.concat(connectionProperty);
      await addSecrets(combinedProperty, combinedvalues);
    }
    else {
      await addSecrets(connectionProperty, formValues);
    }
    setRequestWrapper({
      connectionProperty: connectionProperty,
      connectionDetails: formValues
    })
  };


  function createEmptyObject(keys: string[]): Record<string, any> {
    // keys.push('frequency');
    return keys.reduce((acc, key) => {
      acc[key] = null; // You can set the initial value to whatever you prefer
      return acc;
    }, {} as Record<string, any>);
  }

  useEffect(() => {
    if (linkedServiceConfig) {
      setFormData(linkedServiceConfig.configurationDetails)
      var data = linkedServiceConfig.configurationDetails.map(x => x.name);

      if (linkedServiceConfig.authenticationTypeRequired) {
        data.push('frequency');
        data.push('authenticationType');
        const emptyObject = createEmptyObject(data);
        setFormValues({
          ...emptyObject,
          ['frequency']: frequency,
          ['authenticationType']: selectedAuthType
        });
      }
      else {
        data.push('frequency');
        const emptyObject = createEmptyObject(data);
        setFormValues({
          ...emptyObject,
          ['frequency']: frequency,
        });
      }
      setConnectionProperty(data);

    }
  }, [linkedServiceConfig])

  useEffect(() => {
    getConfigurationDetails()
    allSecretsGetAllSecrets()
    setLoading(false);
  }, [])
  useEffect(() => {
    if(!isEmptyObject(allSecrets) && linkedServiceConfig != null && formValues && authFormValues)
    {
      if (allSecrets) {
       setFormValues(prevFormValues => {
        const updatedValues = { ...prevFormValues };
        // Iterate through formData to check each item
        formData.forEach(item => {
          const secretKey = connectionType+ '-' + item.name;
          // Check if the secretKey exists in allSecrets
          if (allSecrets.hasOwnProperty(secretKey)) {
            // If it does, update the corresponding form value
            updatedValues[item.name] = allSecrets[secretKey];
            
          }
        });
        updatedValues['frequency'] = allSecrets[connectionType +'-frequency'];
        setFrequency(allSecrets[connectionType + '-frequency'])

        if (linkedServiceConfig.authenticationTypeRequired)
        {
            updatedValues['authenticationType'] = allSecrets[connectionType + '-authenticationType'];
        }
        // Update authenticationType if required
        return updatedValues;
      });
      }
      if (linkedServiceConfig.authenticationTypeRequired)
      {
        var authType = linkedServiceConfig.authenticationType.find(x => x.authenticationType == allSecrets[connectionType + '-authenticationType'])
        var authProp = authType.authenticationConfigDetails.map(x => x.name);
        const emptyObject = createEmptyObject(authProp);
        setAuthFormData(authType.authenticationConfigDetails);
        setAuthformValues(emptyObject);
        setSelectedAuthType(allSecrets[connectionType + '-authenticationType']);
      }
    }

  },[allSecrets,linkedServiceConfig])
  
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {

    if(authFormData != null && authFormData.length > 0 && !isEmptyObject(authFormValues))
    {
        setAuthformValues(prevFormValues => {
          const updatedValues = { ...prevFormValues };
          // Iterate through formData to check each item
          authFormData.forEach(item => {
            const secretKey = connectionType + '-' + item.name;
            // Check if the secretKey exists in allSecrets
            if (allSecrets.hasOwnProperty(secretKey)) {
              // If it does, update the corresponding form value
              updatedValues[item.name] = allSecrets[secretKey];
              
            }
          });
          // Update authenticationType if required
          return updatedValues;
        });
    }

  },[selectedAuthType]);

  return (
    <>
      <h2 style={{ paddingBottom: 10 }}>Test Connection Details</h2>
      {(!isEmptyObject(allSecrets) || connectionSuccessfull) && <MaterialButton
        text="Edit Connection"
        variant="contained"
        disabled={!disableEdit}
        startIcon={<EditIcon />}
        className={classes.newButton}
        onClick={() => {setDisableEdit(false); setConnectionSuccessfull(false)}}
      />}
      <form onSubmit={handleSubmit}>
        {(!loading && !loadingKeyValue && formData) &&
          <>
            <Grid container>
              <Grid item lg={6} md={4} xs={12}>
                {formData.map((field) => (
                  <>
                    <Grid container style={{ alignItems: "center" }}>
                      <Grid item lg={4} md={4} xs={12}>
                        <h3>{field.label}</h3>
                      </Grid>
                      <Grid item lg={8} md={4} xs={12}>
                        <TextField
                          key={field.name}
                          type="text"
                          variant='outlined'
                          disabled={disableEdit}
                          value={formValues[field.name] || ''}
                          onChange={handleInputChange(field.name)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                  </>
                ))}
                {(linkedServiceConfig?.authenticationTypeRequired && linkedServiceConfig?.authenticationType) &&
                  <>
                    <Grid container style={{ alignItems: "center" }}>
                      <Grid item lg={4} md={4} xs={12}>
                        <h3>Authentication Type</h3>
                      </Grid>
                      <Grid item lg={8} md={4} xs={12}>
                        <RadioGroup aria-label="position" name="position"value={selectedAuthType} onChange={handleChangeAuthType}>
                          {linkedServiceConfig.authenticationType.map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.authenticationType}
                              control={<Radio  disabled={disableEdit} color="primary" />}
                              label={item.authenticationType}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {authFormData.map((field) => (
                      <>
                        <Grid container style={{ alignItems: "center" }}>
                          <Grid item lg={4} md={4} xs={12}>
                            <h3>{field.label}</h3>
                          </Grid>
                          <Grid item lg={8} md={4} xs={12}>
                            <TextField
                              key={field.name}
                              type="text"
                              variant='outlined'
                              disabled={disableEdit}
                              value={authFormValues[field.name] || ''}
                              onChange={handleAuthDetailsInputChange(field.name)}
                              fullWidth
                              margin="normal"
                            />
                          </Grid>
                        </Grid>

                      </>
                    ))}
                  </>
                }

                <Grid container style={{ alignItems: "center" }}>
                  <Grid item lg={4} md={4} xs={12}>
                    <h3>Frequency</h3>
                  </Grid>
                  <Grid item lg={8} md={4} xs={12}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant='outlined'
                        value={frequency}
                        disabled={disableEdit} 
                        onChange={handleChange}
                      >
                        <MenuItem value={'Daily'}>Daily</MenuItem>
                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={classes.bottomSpace}>
                  {/* <Button  className={classes.newButton} disabled={areAllPropertiesFilled(formValues) || areAllPropertiesFilled(authFormValues)} type="submit" variant="contained" color="primary">
                    Test Connection
                  </Button> */}
                  <MaterialButton
                    text="Test Connection"
                    variant="contained"
                    className={classes.newButton}
                    disabled={areAllPropertiesFilled(formValues) || areAllPropertiesFilled(authFormValues)}
                    type="submit"
                  // onClick={() => {
                  //   refreshFileTable();
                  // }}
                  />
                  <MaterialButton
                    text="Save and Continue"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    className={classes.newButton}
                    onClick={handleSaveConnection}
                    disabled={areAllPropertiesFilled(formValues) || areAllPropertiesFilled(authFormValues) || connectionFailed || !connectionSuccessfull}
                  // onClick={() => {
                  //   refreshFileTable();
                  // }}
                  />
                  {/* <Button disabled={areAllPropertiesFilled(formValues) || areAllPropertiesFilled(authFormValues)} type="submit" variant="contained" color="primary">
                    Save Connection
                  </Button> */}
                </div>
              </Grid>
              <Grid item lg={6} md={4} xs={12}>
                <Grid container style={{ alignItems: "center",  paddingLeft: 40, paddingRight: 40 }}>
                  <Grid item lg={9} md={4} xs={12}>
                    {(testingConn || addingSecret) &&
                      <div style={{ display: "flex", alignItems: "center", height: 75 }}>
                        <CircularProgress size={30} className={classes.fabProgress} />
                        <Typography variant='h6' style={{ paddingLeft: 15, color: '#86BC25' }}>Testing Connection...</Typography>
                      </div>
                    }
                    {connectionSuccessfull &&
                      <div style={{ display: "flex", alignItems: "center", height: 75 }}>
                        <CheckCircleOutlineIcon style={{ width: 30, height: 30, color: '#86BC25' }}></CheckCircleOutlineIcon>
                        <Typography variant='h6' style={{ paddingLeft: 15, color: '#86BC25' }}>Connected Succesfully</Typography>
                      </div>
                    }
                    {(!connectionSuccessfull && connectionFailed) &&
                      <>
                        <div style={{ display: "flex", alignItems: "center", height: 75 }}>
                          <BlockIcon style={{ width: 30, height: 30, color: "red" }}></BlockIcon>
                          <Typography variant='h6' style={{ paddingLeft: 15, color: "red" }}>Connection Failed</Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", backgroundColor: "ghostwhite" }}>
                          <Typography style={{ paddingLeft: 15, color: "red" }}>{connectionFailedLog}</Typography>
                        </div>
                      </>
                    }
                  </Grid>
                </Grid>
                <Grid container style={{ alignItems: "center", paddingLeft: 40, paddingRight: 40  }}>
                  <Grid item lg={9} md={4} xs={12}>
                    {(saveingConn) &&
                      <div style={{ display: "flex", alignItems: "center", height: 75 }}>
                        <CircularProgress size={30} className={classes.fabProgress} />
                        <Typography variant='h6' style={{ paddingLeft: 15, color: '#86BC25' }}>Saveing Connection...</Typography>
                      </div>
                    }
                    {(saveingFailed) &&
                      <>
                        <div style={{ display: "flex", alignItems: "center", height: 75 }}>
                          <BlockIcon style={{ width: 30, height: 30, color: "red" }}></BlockIcon>
                          <Typography variant='h6' style={{ paddingLeft: 15, color: "red" }}>Saveing Failed</Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", backgroundColor: "ghostwhite" }}>
                          <Typography style={{ paddingLeft: 15, color: "red" }}>{connSaveFailedLog}</Typography>
                        </div>
                      </>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </>
        }
      </form>
      {(loading || loadingKeyValue )  && <CircularProgress size={84} className={classes.progress} />}
    </>

  );
};

export default ConnectionForm;