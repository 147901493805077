import DateFnsUtils from "@date-io/date-fns";
import { Box, CircularProgress, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useEffect, useState } from "react";
import { theme } from "src/theme";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { Autocomplete } from "@material-ui/lab";
import ConfirmDialog from "../utils/ConfirmDialog";
import MuiButton from "../utils/Button";
import { getAutorenewedToken } from "../auth/msalUtils";
import Notification from "src/components/utils/Notification";
import "./DataUpload.css";

interface Project
{
    projectId:number;
    projectName:string;
    projectPhase:string;
    projectPortfolio:string;
    projectDescription:string;
    projectOwner:string;
    localCurrencyCode:string;
    projectStartDate:Date;
    projectEndDate:Date
}
interface Steps{
  projectSaved:any
}
const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2),
  },
  fabProgress: {
    color: theme.palette.primary.main,
    marginTop: "10%",
    marginLeft: "50%",
    marginBottom: "10%",
  },
}));

export default function ProjectPortfolioTab(props: Steps)
{
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title:"Are you sure you want to delete this project?",
    subtitle: "You can't undo this operation",
    type: "",
  });

 
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [projectStartDate, setProjectStartDate] = useState<Date | null>(null);
  const [projectEndDate, setProjectEndDate] = useState<Date | null>(null);
  const [projectName, setProjectName] = useState<string | null>("");
  const [projectId, setProjectId] = useState<number | null>();
  const [enableUpdate, setEnableUpdate] = useState<boolean>(false);
  const [projectPhase, setProjectPhase] = useState<string | null>("");
  const [projectDescription, setProjectDescription] = useState<string | null>("");
  const [projectOwner, setProjectOwner] = useState<string | null>("");
  const [projectPortfolio, setProjectPortfolio] = useState<string>("");
  const [portfolios, setPortfolios] = useState<string[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [localCurrencyCode, setLocalCurrencyCode] = useState<string | null>("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addProject = async () => {
    if(!projects.find(x => x.projectName == projectName))
    {
      setIsLoading(true);
      // GET allowed blob storage details
      fetch(
        process.env.REACT_APP_API_PATH +
        "/project",
        {
          method: "POST",
          headers: {
            Accept: 'application.json',
            'Content-Type': 'application/json',
            authorization: await getAutorenewedToken()
          },
          body: JSON.stringify({
          projectName:projectName,
          projectDescription:projectDescription,
          projectPhase:projectPhase,
          projectOwner:projectOwner,
          projectStartDate:projectStartDate,
          projectEndDate:projectEndDate,
          ProjectPortfolio:projectPortfolio,
          localCurrencyCode:localCurrencyCode
          })
        }
      )
        .then(async (response) => {
          // check for error response
          const data = await response.json();
          if (!response.ok) {
            setNotify({
              isOpen: true,
              message: "Failed to add project",
              type: "error",
            });
            // get error message from body or default to response statusText
            return Promise.reject(response.statusText);
          }
          setNotify({
            isOpen: true,
            message: "Project addedd Successfully",
            type: "success",
          });
          await getProject();
          await getProtfolio();
          clearProject()
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
    else{
      setNotify({
        isOpen: true,
        message: "Project already exists.",
        type: "error",
      });
    }
  };

  const updateProject = async () => {
    if(projects.find(x => x.projectId == projectId))
    {
      setIsLoading(true);
      // GET allowed blob storage details
      fetch(
        process.env.REACT_APP_API_PATH +
        "/project/UpdateProject",
        {
          method: "POST",
          headers: {
            Accept: 'application.json',
            'Content-Type': 'application/json',
            authorization: await getAutorenewedToken()
          },
          body: JSON.stringify({
          projectId:projectId,  
          projectName:projectName,
          projectDescription:projectDescription,
          projectPhase:projectPhase,
          projectOwner:projectOwner,
          projectStartDate:projectStartDate,
          projectEndDate:projectEndDate,
          ProjectPortfolio:projectPortfolio,
          localCurrencyCode:localCurrencyCode
          })
        }
      )
        .then(async (response) => {
          // check for error response
          const data = await response.json();
          if (!response.ok) {
            setNotify({
              isOpen: true,
              message: "Failed to update project",
              type: "error",
            });
            // get error message from body or default to response statusText
            return Promise.reject(response.statusText);
          }
          setNotify({
            isOpen: true,
            message: "Project updated Successfully",
            type: "success",
          });
          await getProject();
          await getProtfolio();
          clearProject()
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
    else{
      setNotify({
        isOpen: true,
        message: "Project doesn't exists.",
        type: "error",
      });
    }
  };

  const deleteProject = async (project:Project) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setIsLoading(true);
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
      "/project",
      {
        method: "DELETE",
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json',
          authorization: await getAutorenewedToken()
        },
        body: JSON.stringify(project)
      }
    ).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        
        await getProject();
        await getProtfolio();
        clearProject();
        setNotify({
          isOpen: true,
          message: "Project deleted Successfully",
          type: "success",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const getProtfolio = async () => {
    setIsLoading(true);
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
      "/project/getPortfolio",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setPortfolios(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const getProject = async () => {
    setIsLoading(true);
    // GET allowed blob storage details
    fetch(
      process.env.REACT_APP_API_PATH +
      "/project",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setProjects(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  function checkProjectDetails() {
    console.log("inside")
  }
  const clearProject = () => {
    setProjectName("")
    setProjectDescription("")
    setProjectOwner("")
    setProjectPortfolio("")
    setProjectPhase("")
    setProjectStartDate(null)
    setProjectEndDate(null)
    setLocalCurrencyCode("")
    setProjectId(null)
    setEnableUpdate(false)
  }
  const setProjectDetails = (proj:Project) => {
    setEnableUpdate(true)
    setProjectId(proj.projectId)
    setProjectName(proj.projectName)
    setProjectDescription(proj.projectDescription)
    setProjectOwner(proj.projectOwner)
    setProjectPortfolio(proj.projectPortfolio)
    setProjectPhase(proj.projectPhase)
    setProjectStartDate(proj.projectStartDate)
    setProjectEndDate(proj.projectStartDate)
    setLocalCurrencyCode(proj.localCurrencyCode)
  }
 
  useEffect(() => {
    getProject();
    getProtfolio();
  },[])
  
  useEffect(() => {
    if((projectName != null && projectName != "") || (projectPortfolio!= null && projectPortfolio != "") ||
       (projectDescription != null && projectDescription != "")  || (projectOwner != null && projectOwner != "")  ||
       (projectPhase != null && projectPhase != "")  || projectStartDate != null || projectEndDate != null  || (localCurrencyCode != null && localCurrencyCode != "" ) )
    {
      props.projectSaved(false)
    }
    else
    {
      props.projectSaved(true)
    }
  },[projectName, projectDescription,projectPortfolio,projectPhase,projectOwner,localCurrencyCode,projectEndDate,projectStartDate])

  return (
  <>
  { !isLoading ? (
    <>
    <ConfirmDialog
    confirmDialog={confirmDialog}
    setConfirmDialog={setConfirmDialog}></ConfirmDialog>
  <Notification notify={notify} setNotify={setNotify} />
  <Typography className={classes.paddingLeft}  variant="h5" component="div">Project & Portfolio Configuration</Typography>
  <Typography className={classes.paddingLeft} variant="caption" component="div">Before ingesting any project data into IMCP you must define and configure your projects in the platform. You can add projects individually or upload in bulk via the provided CSV template. Multiple projects can be grouped into portfolios.</Typography>
  <Typography className={classes.padding} />
   <Grid container>
      <Grid item xs={6}>
        <Grid container justifyContent="center" spacing={1}>
        <TextField id="outlined-basic" value={projectName} label="Project Name"  onChange={(e) =>{ setProjectName(e.target.value )}} variant="outlined"  style={{ margin: "1%", width: "100%" }} />
        <TextField id="outlined-basic" value={projectDescription} onChange={(e) =>setProjectDescription(e.target.value )} label="Project Descripition" variant="outlined" multiline rows={3} style={{ margin: "1%", width: "100%" }} />
        <Autocomplete
           style={{ width: "100%", marginRight:"2%" }}
           id="Project Portfolio"
           freeSolo
           disableClearable={true}
           onChange={(event, value) => setProjectPortfolio(value)}
           inputValue={projectPortfolio}
           //openText={projectPortfolio}
           options={portfolios}
           renderInput={(params) => <TextField {...params} value={projectPortfolio}  variant="outlined" style={{ margin: "1%", width: "100%" }}  label="Project Portfolio" onChange={(e) =>setProjectPortfolio(e.target.value )}/>}
         />
        <TextField id="outlined-basic" value={projectPhase} onChange={(e) => setProjectPhase(e.target.value )}  label="Project Phase" variant="outlined"  style={{ margin: "1%", width: "100%" }} />
        <TextField id="outlined-basic" value={projectOwner} onChange={(e) =>setProjectOwner(e.target.value )} label="Project Owner" variant="outlined"  style={{ margin: "1%", width: "100%" }} /> 
        <TextField id="outlined-basic" value={localCurrencyCode} onChange={(e) =>setLocalCurrencyCode(e.target.value )} label="Local Currency Code" variant="outlined"  style={{ margin: "1%", width: "100%" }} /> 
        {/* <TextField id="outlined-basic" label="Project Portfolio" variant="outlined"  style={{ margin: "2%", width: "100%" }} /> */}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              //maxDate={new Date()}
              disableToolbar
              variant="inline"
              format="dd-MMM-yyyy"
              //views={['year', 'month']}
              autoOk
              margin="normal"
              label="Project start Date"
              value={projectEndDate}
              style={{ margin: "1%", width: "100%" }}
              inputVariant="outlined"
              onChange={(date) => setProjectEndDate(date)}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd-MMM-yyyy"
              //views={['year', 'month']}
              autoOk
              margin="normal"
              label="Project End Date"
              value={projectStartDate}
              style={{ margin: "1%", width: "100%" }}
              inputVariant="outlined"
              onChange={(date) => setProjectStartDate(date)}
            />
          </MuiPickersUtilsProvider>
                <Grid container item xs={12} justifyContent="flex-end">
                  {!enableUpdate &&
                    <MuiButton variant="outlined" text="Add Project" disabled={!(projectName && projectPortfolio && projectDescription && projectOwner && projectPhase && projectStartDate && projectEndDate && localCurrencyCode)} type="submit" onClick={addProject} startIcon={<AddIcon />} />
                  }
                  {enableUpdate &&
                    <>
                      <MuiButton variant="outlined" text="Cancel" type="submit" onClick={clearProject} startIcon={<CloseIcon />} />
                      <MuiButton variant="outlined" text="Update Project" disabled={!(projectName && projectPortfolio && projectDescription && projectOwner && projectPhase && projectStartDate && projectEndDate && localCurrencyCode)} type="submit" onClick={updateProject} startIcon={<AutorenewIcon />} />
                    </>
                  }

                </Grid>
        </Grid>
        
        
      </Grid>
      <Grid item xs={2}>

      </Grid>
      <Grid item xs={4}>
        <Box
          border={1}
          borderLeft={1}
          borderRight={1}
          style={{ height: "100%", borderColor: "rgba(0,0,0,0.23)" }}
          //borderColor="rgba(0,0,0,0.23)"
          borderRadius="2%" >
          <Typography 
            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70px", borderBottom: "1px solid rgba(0, 0, 0, 0.23)", fontWeight: "bold" }} 
            variant="h6" component="div">
            Existing Projects
          </Typography>
          <List style={{ height:"550px", overflow:"auto", margin :"2%" }}>
            {projects.map((pro, idx) => (
              <ListItem
                key={idx}
              >
                <ListItemText id={idx} primary={pro.projectName} />
                <EditIcon fontSize="medium" style={{ color: "#86BC25", cursor:"pointer" , marginRight:"2%" }}    onClick={() => setProjectDetails(pro)} />
                <CloseIcon fontSize="medium"  style={{ color: "red", cursor:"pointer" }} onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title:
                      "Are you sure you want to delete " + pro.projectName +"?",
                    subtitle: "Deleting an existing Project will also remove all associated data across the entire platform including any removing associated data from any existing dashboards. This action cannot be undone. Please confirm before proceeding.",
                    onConfirm: async () => {
                      await deleteProject(pro);
                      //await getProject();
                      //onDelete(row);
                    },
                  });
                }} />
               
                
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
  </Grid>
    </>
  ): (
    <CircularProgress size={84} className={classes.fabProgress} />
  )}
  </>
  );
} 


