import { CircularProgress, Divider, FormControl, Grid, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles, withStyles } from "@material-ui/core";
import MaterialButton from 'src/components/utils/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import { getAutorenewedToken } from "../auth/msalUtils";
import { useEffect, useState } from "react";
import { theme } from "src/theme";
import BlockIcon from '@material-ui/icons/BlockOutlined';
import CheckIcon from "@material-ui/icons/Check";
import Notification from "../utils/Notification";

const useStyles = makeStyles((theme) => ({

    newButton: {
        marginRight: '10px',
        fontSize: '14px',
        float: 'right'
    },
    fabProgress: {
        color: theme.palette.primary.main,
        marginTop: "20%",
        marginLeft: "35%",
        marginBottom: "15%",
      },
})
)
const StyledTableCell = withStyles(theme => ({
    root: {
      border: '1px solid #dddddd',
      padding: theme.spacing(1),
     // background: '#f2f2f2',
    },
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
       // backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

 interface DataSetDto
 {
    dataSetId:number
    dataSet:string
 }
 
 interface ColumnDetails
 {
    columnDetailId:number
    name:string
    type:string
    sourceType:string
    sourceColumn:string
    description:string
 }
 interface TableColumnMapping
 {
    destinationColumn:string,
    sourceColumn:string,
    linkedServiceId:string,
    isActive:boolean
 }
 interface TableMapping
 {
    templateId?:number,
    destinationTable:string,
    sourceTable:string,
    sourceSchema:string,
    linkedServiceName:string,
    linkedServiceId:string,
    tableColumnMapping:TableColumnMapping[]
 }

 interface Source
 {
    name:string
    type:string
 }
 interface Sink
 {
    name:string
    type:string
    physicalType:string
 }
 interface Mapping
 {
    source:Source
    sink:Sink
 }
 interface ColumnMapping
 {
    type:string
    mappings:Mapping[]
 }
 interface Dashboard
 {
    dashboardId:number
    dashboardName:string
 }
interface SourceTableDetails
{
    sourceTableName:string,
    sourceTableSchema:string,
    linkedServiceName:string,
}
 interface DataMappingProps
 {
    linkedServiceId:string
    sourceConnection:string
    destinationTable?:string
    destinationTableId?:number
    mappingCompleted:any
 }
const ColumnMapping = (props:DataMappingProps) => {
  const classes = useStyles();
  const [dataSet, setDataSet] = useState<DataSetDto[]>([]);
  //const [dataSet, setDataSet] = useState<DataSetDto[]>([]);
  const [columnDetail, setColumnDetail] = useState<ColumnDetails[]>([]);
  const [tableMapping, setTableMapping] = useState<TableMapping | null>();
  const [tableColumnMapping, setTableColumnMapping] = useState<TableColumnMapping[]>([]);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<number>();
  const [sourceColumnDetail, setSourceColumnDetail] = useState<Source[]>([]);
  const [columnMapping, setColumnMapping] = useState<ColumnMapping>();
  const [destTable, setDestTable] = useState<number | null>(null);
  const [srcTable, setSrcTable] = useState<SourceTableDetails[]>([]);
  const [selectedSoureTable, setSelectedSoureTable] = useState<SourceTableDetails | null>();
  const [selectedSrctbl, setSelectedSrctbl] = useState<string | null>();
  const [loading, setLoading] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

const getSourceTables = async () => {

    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/getSourceTable?linkedServiceId="+ props.linkedServiceId, {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        setSrcTable(data);
    }).catch((error) => {
            setLoading(false);
    });
}
const getcolumnmapping = async () => {

    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/getcolumnmapping?linkedServiceId="+ props.linkedServiceId +"&templateId="+ props.destinationTableId, {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        setTableMapping(data)
        data.tableColumnMapping ? setTableColumnMapping(data.tableColumnMapping) :  setTableColumnMapping([])
        
    }).catch((error) => {
            setLoading(false);
    });
} 

const getSourceTablesColumns = async () => {

    setSourceColumnDetail([]);
    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/getSourceTableColumn?linkedServiceId="+ props.linkedServiceId+"&sourceTableName="+selectedSoureTable?.sourceTableName, {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        setSourceColumnDetail(data)

        setColumnDetail(prevItems =>
            prevItems.map(item => ({
              ...item,
              sourceColumn: ""
            }))
          );
    }).catch((error) => {
            setLoading(false);
    });
} 

const handleDestChange = (event) => {
    setDestTable(event.target.value);
};

const handleSourceChange = (event) => {
    var reseult = srcTable.find(x => x.sourceTableName == event.target.value)
    setSelectedSoureTable(reseult);
    if(reseult)
    {
        setSelectedSrctbl(reseult?.sourceTableName)
    }
};

const processcolumnmapping = async () => {
    const tableColumnMappingArray: TableColumnMapping[] = columnDetail.map(({ name, sourceColumn }) => ({
        destinationColumn: name,
        sourceColumn,
        linkedServiceId: props.linkedServiceId, // Example of a default value
        isActive: true, // Example of a default value
    }));

    const tableMapping: TableMapping =  (
    {
        destinationTable:props.destinationTable,
        templateId:props.destinationTableId,
        sourceTable: selectedSoureTable?.sourceTableName,
        linkedServiceId:props.linkedServiceId,
        tableColumnMapping:tableColumnMappingArray

    })
    fetch(process.env.REACT_APP_API_PATH+"/dataconfiguration/column-mapping", {
        method: "POST",
        headers: { 
          Accept: 'application.json',
          'Content-Type': 'application/json',
          authorization: await getAutorenewedToken() 
        },
        body:  JSON.stringify(tableMapping)

    }).then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
            setNotify({
                isOpen: true,
                message: "Failed to save mapping",
                type: "error",
              });
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        else
        {
            props.mappingCompleted(true);
            setNotify({
                isOpen: true,
                message: 'Mapping saved succesfully',
                type: "success",
              });
        }
    }).catch((error) => {
        setNotify({
            isOpen: true,
            message: "Failed to save mapping",
            type: "error",
          });
      setLoading(false);
    });
}

const processDataMapping = async () => {
    fetch(process.env.REACT_APP_API_PATH+"/dataconfiguration/process-data-mapping", {
        method: "POST",
        headers: { 
          Accept: 'application.json',
          'Content-Type': 'application/json',
          authorization: await getAutorenewedToken() 
        },
        body:  JSON.stringify(
          {
            sourceTable: selectedSoureTable?.sourceTableName,
            destinationTable:props.destinationTable,
            columnMapping:columnMapping,
            sourceTableSchema:selectedSoureTable?.sourceTableSchema,
            LinkedServiceName:selectedSoureTable?.linkedServiceName
          })

    }).then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
            setNotify({
                isOpen: true,
                message: "Failed to save mapping",
                type: "error",
              });
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        else
        {
            await processcolumnmapping();
        }
    }).catch((error) => {
        setNotify({
            isOpen: true,
            message: "Failed to save mapping",
            type: "error",
          });
      setLoading(false);
    });
}

function hasEmptyProperty(){
    return columnDetail.every(x => x.sourceColumn != '' && x.sourceColumn != null &&  x.sourceColumn != undefined 
    && x.sourceType != '' && x.sourceType != null && x.sourceType != undefined && x.sourceType == x.type) && props.destinationTable != null && selectedSoureTable != null 
    && selectedSoureTable?.sourceTableName != '' && selectedSoureTable != undefined 
}


const handleSelectChange = (event, row) => {

    const selectedValues = event.target.value;
    var column = columnDetail.find(x => x.name == row.name);
    var columnSelected = sourceColumnDetail.find(x => x.name == selectedValues);

    setColumnDetail(prevItems =>
        prevItems.map(item =>
        item.name === row?.name ? { ...item, sourceType: columnSelected?.type, sourceColumn:columnSelected?.name } : item
        )
    )
    
    setColumnMapping(prevItems => {
        // If prevItems or prevItems.mappings is undefined, return prevItems directly
        if (!prevItems || !prevItems.mappings) {
            return prevItems;
        }
        // Map over the mappings array and update the sink.name property if the source.name matches
        const updatedMappings = prevItems.mappings.map(item => {
            
            if (item.sink.name === column?.name) { 
                return { ...item, source: { ...item.source, name: columnSelected?.name, type : columnSelected?.type } };
            }
            return item;
        });
        // Return the updated object with the modified mappings
        return { ...prevItems, mappings: updatedMappings };
    });
    
  };

    const setSourceColumnMapping = () => {

        var result = srcTable.find(x => x.sourceTableName == tableMapping?.sourceTable)
        if(result)
        {
            setSelectedSrctbl(result?.sourceTableName)
        }
        selectedSoureTable == null || selectedSoureTable.sourceTableName == ""  ? setSelectedSoureTable(result) : setSelectedSoureTable(selectedSoureTable)

        if (tableMapping?.sourceTable == selectedSoureTable?.sourceTableName) {
            if (tableColumnMapping?.length > 0) {
                setColumnDetail(prevItems => {
                    return prevItems.map(item => {
                        var tab = tableColumnMapping?.find(x => x.destinationColumn === item.name);
                        if (tab) {
                            if (sourceColumnDetail.length > 0) {
                                var columnSelected = sourceColumnDetail.find(x => x.name === tab?.sourceColumn);
                                if (columnSelected) {
                                    return { ...item, sourceType: columnSelected.type, sourceColumn: columnSelected.name };
                                }
                            }
                        }
                        return item; // Return the original item if no modifications were made
                    });
                });
            }

            setColumnMapping(prevItems => {
                // If prevItems or prevItems.mappings is undefined, return prevItems directly
                if (!prevItems || !prevItems.mappings) {
                    return prevItems;
                }
                // Map over the mappings array and update the sink.name property if the source.name matches
                const updatedMappings = prevItems.mappings.map(item => {
                    var tab = sourceColumnDetail?.find(x => x.name === item.sink.name);
                    if (tab) { 
                        return { ...item, source: { ...item.source, name: tab.name, type : tab.type} };
                    }
                    return item;
                });
                // Return the updated object with the modified mappings
                return { ...prevItems, mappings: updatedMappings };
            });
        }


    };
  

const resetMapping =()=>{
    setColumnMapping(prevItems => {
        // If prevItems or prevItems.mappings is undefined, return prevItems directly
        if (!prevItems || !prevItems.mappings) {
            return prevItems;
        }
        // Map over the mappings array and update the sink.name property if the source.name matches
        const updatedMappings = prevItems.mappings.map(item => { 
                return { ...item, source: { ...item.source, name: '', type : ''} };
        });
        // Return the updated object with the modified mappings
        return { ...prevItems, mappings: updatedMappings };
    });
    setColumnDetail(prevColumnDetail => (
        prevColumnDetail.map(item => ({
          ...item,
          sourceColumn: null,
          sourceType: null
        }))
      ));

}
const getDestinationColumns = async () => {

    fetch(process.env.REACT_APP_API_PATH + "/dataconfiguration/dataColumn?dataSet="+props.destinationTable, {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() }
    }).then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
       
        setColumnDetail(data);
        if(data.length > 0)
        {
            const resultJsonData = {
                type: "TabularTranslator",
                mappings: data.map(item => ({
                source: {
                    name: "",
                    type: ""
                },
                sink: {
                    name: item.name, // Convert snake_case to PascalCase
                    type: item.type,
                    physicalType: item.type
                }
                }))
            };

        setColumnMapping(resultJsonData);

        }
    }).catch((error) => {
            setLoading(false);
    });
} 



useEffect(() => {
    getSourceTables()
    getDestinationColumns()
    getcolumnmapping()
    resetMapping()
    setSelectedSoureTable(null)
    setSourceColumnDetail([])
},[props.destinationTable])


useEffect(() => {
if(tableMapping)
{
    var result = srcTable.find(x => x.sourceTableName == tableMapping.sourceTable)
    if(result)
    {
        setSelectedSoureTable(result)
        setSelectedSrctbl(result?.sourceTableName)
    }
}
},[tableMapping])

useEffect(() => {
    if(selectedSoureTable)
    {
        getSourceTablesColumns()
    } 
},[selectedSoureTable])

useEffect(() => {

    if(columnDetail.length>0 && tableColumnMapping?.length>0 && sourceColumnDetail.length > 0)
    {
        setSourceColumnMapping()
    } 
},[tableMapping, sourceColumnDetail ])





const dataMapping = () =>{
return <>
    <Notification notify={notify} setNotify={setNotify} />
            <Grid container style={{paddingTop:20}}>
                <Grid item xs={12}>
                    <MaterialButton
                        text="Save"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        className={classes.newButton}
                        disabled ={!hasEmptyProperty()}
                        onClick={() => {
                            processDataMapping();
                            
                        }}
                    />
                    <MaterialButton
                        text="Reset"
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        className={classes.newButton}
                        onClick= {resetMapping}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ paddingTop: theme.spacing(4) }}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell colSpan={2} style={{borderRight:"2px solid"}}>
                                <Grid item xs={6} style={{ paddingRight: 20 }}>
                                    <h2 style={{ paddingBottom: 10 }}>Source</h2>
                                    <h4 style={{ paddingBottom: 10 }}>Connected to:</h4>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4} style={{ paddingRight: 20, display: "flex", alignItems: "center" }}>
                                        <h4 style={{ paddingBottom: 10 }}>Source Db table</h4>
                                    </Grid>
                                    <Grid item xs={8} style={{ paddingRight: 20 }}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant='outlined'
                                                value={selectedSoureTable?.sourceTableName ? selectedSoureTable?.sourceTableName :""}
                                                onChange={handleSourceChange}

                                            >
                                                {srcTable.map((item, index) => (
                                                    <MenuItem key={index} value={item.sourceTableName}>{item.sourceTableName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} style={{borderRight:"2px solid"}}>
                                <Grid item xs={6} style={{ paddingRight: 20 }}>
                                    <h2 style={{ paddingBottom: 40 }}>Destination</h2>
                                </Grid>
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                                <Grid item xs={6} style={{ paddingRight: 20 }}>
                                    <div>
                                        <h2>Mapping</h2>
                                    </div>
                                    
                                </Grid>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell><h4>Source Column</h4></StyledTableCell>
                            <StyledTableCell style={{borderRight:"2px solid"}}><h4>Source Data Type</h4></StyledTableCell>
                            <StyledTableCell><h4>Destination Column</h4></StyledTableCell>
                            <StyledTableCell><h4>Description</h4></StyledTableCell>
                            <StyledTableCell style={{borderRight:"2px solid"}}><h4>Destination Data Type</h4></StyledTableCell>
                            <StyledTableCell><h4>Map Status</h4></StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {columnDetail.map(row => (
                            <StyledTableRow key={row.columnDetailId}>
                                <StyledTableCell>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={row.sourceColumn ? row.sourceColumn : ""}
                                            onChange={(event) => handleSelectChange(event, row)}
                                        >
                                            {sourceColumnDetail.map((item, index) => (
                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </StyledTableCell>
                                <StyledTableCell style={{borderRight:"2px solid"}}>{row.sourceType}</StyledTableCell>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell>{row.description}</StyledTableCell>
                                <StyledTableCell style={{borderRight:"2px solid"}}>{row.type}</StyledTableCell>
                                <StyledTableCell>
                                    <div style={{display: "flex", alignItems: "center", justifyContent:"space-between"}}>
                                    {(row.sourceType && row.sourceType == row.type) && <><CheckIcon style={{ color: "green" }} /> <Typography>Mapped successfully</Typography></>}
                                    {(row.sourceType && row.sourceType != row.type) && <><BlockIcon style={{ color: "red" }} /> <Typography>Mismatch in datatype</Typography></>}
                                    </div>
                                    
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </>
}

    return (
        <>
            <div>{!loading && dataMapping()}</div>
            <div>{loading &&  <CircularProgress size={84} className={classes.fabProgress} />}</div>
        </>
    );
        
        
}
export default ColumnMapping;