import { Avatar, Badge, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton,
  MenuItem, Paper, SwipeableDrawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import MaterialButton from "../utils/Button";
import CommentIcon from "@material-ui/icons/ChatBubbleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import MuiButton from '../utils/Button';
import { useEffect, useState } from 'react';
import { getAutorenewedToken } from '../auth/msalUtils';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Notification from "src/components/utils/Notification";
import { format } from 'date-fns';
import mixpanel from 'mixpanel-browser';

interface DashboardVisual{
 dashboardVisualsId:number;
 dashboardVisualsName:string
}
interface DashboardComments{
 dashboardCommentId?:number;
 dashboardId:number;
 dashboardVisualId?:number;
 userId:number;
 reportDate:Date;
 comment:string;
 createdOn:Date;
 modifiedOn:Date;
 dashboardVisual:DashboardVisual;
 projectId:number;
 user:User;
 project:Project;
}
interface User
{
   userId:number;
   userName:string;
   userFirstName:string;
   userLastName:string;
}
interface DashboardVisual
{
   dashboardVisualsId:number;
   dashboardVisualsName:string;
   dashboardId:number;
   avtive:boolean
}
interface Project
{
   projectId:number;
   projectName:string;
   projectPhase:string;
   projectDescription:string
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';
type Picker = 'filter' | 'comment';
const anchor = 'right';

const useStyles = makeStyles((theme) => ({
 newButtonFirst: {
   marginLeft: "0px",
   float: "left",
   marginRight: "1%",
   fontSize: "14px",
 },
 pageContent: {
   padding: theme.spacing(2),
 },
 comment: {
   width: "100%",
   overflow: "auto",
   height: "80%"
 },
 root: {
   display: "flex",
   flexDirection: "column",
   marginBottom: theme.spacing(1),
   marginLeft: theme.spacing(3),
 },
 statsItem: {
   alignItems: "center",
   display: "flex",
 },
 statsIcon: {
   marginRight: theme.spacing(1),
 },
 cardcontent: {
   padding: 0,
 },
 
 fontBold: {
   fontWeight: "bold"
 },
 filterheading: {
   paddingTop:theme.spacing(1),
   paddingLeft:theme.spacing(2),
   fontWeight: "bold"
 },
 bottomSpace: {
   minHeight: 40
 },
 card: {
   display: "flex",
   flexDirection: "column",
   marginBottom: theme.spacing(2),
   marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
   borderRadius: "5px",
   padding: "0px",
   border: "0px",
   backgroundColor: "#fafafa"
 },
 dataUpload: {
   marginTop: theme.spacing(3),
 },
 avatar: {
   backgroundColor: "#86BC25",
 },
 pageHeader: {
   padding: "15px",
   display: "flex",
   marginLeft: theme.spacing(2),
   marginRight: theme.spacing(2),
 },
 pageIcon: {
   display: "inline-block",
   padding: theme.spacing(2),
   color: theme.palette.text.primary,
   fontSize: 18,
 },
 pageTitle: {
   paddingLeft: theme.spacing(4),
   "& .MuiTypography-subtitle2": {
     opacity: "0.6",
   },
 },
 BackButton: {
   display: "inline-block",
   paddingTop: theme.spacing(1),
   color: theme.palette.text.primary,
   marginLeft: "auto",
   fontSize: 18,
 },
 table: {
   
   "& thead th": {
     fontWeight: "600",
     color: theme.palette.primary.main,
     backgroundColor: theme.palette.secondary.light,
     padding:"10px",
   },
   "& tbody td": {
     padding:"10px",
    // fontWeight: "bold",
   },
 },
 filter:{
   padding:"1%",
   display:"flex",
   alignItems:"center"
 },
 customBadge: {
   backgroundColor: "gray",
   color: "blck"
 }
}));

type DashBoardProp = {
 dashboardId: number;
 dashboardName: string;
 addComment: boolean;
};

export default function DashboardComment(props: DashBoardProp) {
 const [isLoading, setIsLoading] = useState(true);
 const [comment, setComment] = useState<string>('');
 const [visualId, setVisualId] = useState<number | null>(null);
 const [filterVisualId, setFilterVisualId] = useState<number | null>(null);
 const [projectId, setProjectId] = useState<number | null>(null);
 const [filterProjectId, setfilterProjectId] = useState<number | null>(null);
 const [filterDate, setFilterDate] = useState<Date | null>(null);
 const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
 const classes = useStyles();
 const [visuals, setVisuals] = useState<DashboardVisual[]>([]);
 const [project, setProject] = useState<Project[]>([]);
 const [dashboardComment, setDashboardComment] = useState<DashboardComments[]>([]);
 const [filterdashboardComment, setFilterDashboardComment] = useState<DashboardComments[]>([]);
 const [user, setUser] = useState<User>();
 const [state, setState] = useState({right: false});
 const [openForm, setOpenForm] = useState(false);
 const [commentCount, setCommentCount] = useState<number | null>(null);
 const theme = useTheme();

 const [notify, setNotify] = useState({
   isOpen: false,
   message: "",
   type: "",
 });
 
 const handleChange = (event:any) => {
   setVisualId(event.target.value);
 };

 const handleFilterChange = (event:any) => {
   setFilterVisualId(event.target.value);
 };

 const handleFilterProject = (event:any) => {
   setfilterProjectId(event.target.value);
 };
 const handleProjectChange = (event:any) => {
   setProjectId(event.target.value);
 };

 const handleDateChange = (date:any) => {
   setSelectedDate(date);
 };

 const handleFilterDateChange = (date:any) => {
   setFilterDate(date)
 };
 const clearFilter =() => {
   setFilterDate(null);
   setFilterVisualId(null);
   setfilterProjectId(null);
   setFilterDashboardComment(dashboardComment);
 }
  const mxPanelCommentHandler = (action:any) => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ? process.env.REACT_APP_MIXPANEL_TOKEN : "");
    mixpanel.track(action,{
      user_id:window.sessionStorage.getItem('USER_ID'),
      dashboardName:props.dashboardName,
      totalComments:dashboardComment.length
    });
  }

 const toggleDrawer =
   (anchor: Anchor, open: boolean) =>
     (event : React.KeyboardEvent | React.MouseEvent) => {
       if (
         event &&
         event.type === 'keydown' &&
         ((event as React.KeyboardEvent).key === 'Tab' ||
           (event as React.KeyboardEvent).key === 'Shift')
       ) {
         return;
       }
       if(!open)
       {
         resetDetail();
         clearFilter();
          commnetCount();
          mxPanelCommentHandler("Dashboard Comments Close");
        }
        if(open){
          commnetCount();
          mxPanelCommentHandler("Dashboard Comments Open");
        }
        setState({ ...state, [anchor]: open });
       
     };
 const getDashboardVisuals = async () => {
   setIsLoading(true);
   // GET allowed blob storage details
   fetch(
     process.env.REACT_APP_API_PATH +
     "/dashboardsVisual?dashboardId="+props.dashboardId,
     {
       method: "GET",
       headers: { authorization: await getAutorenewedToken() },
     }
   )
     .then(async (response) => {
       const data = await response.json();
       // check for error response
       if (!response.ok) {
         // get error message from body or default to response statusText
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
       }
       setVisuals(data);
       setIsLoading(false);
     })
     .catch((error) => {
       setIsLoading(false);
     });
 };
 const getDashboardComments = async () => {
   setIsLoading(true);
   // GET allowed blob storage details
   fetch(
     process.env.REACT_APP_API_PATH +
     "/dashboardComment?dashboardId="+props.dashboardId,
     {
       method: "GET",
       headers: { authorization: await getAutorenewedToken() },
     }
   )
     .then(async (response) => {
       const data = await response.json();
       // check for error response
       if (!response.ok) {
         // get error message from body or default to response statusText
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
       }
       setDashboardComment(data);
       setFilterDashboardComment(data);
       setIsLoading(false);
     })
     .catch((error) => {
       setIsLoading(false);
     });
 };
 const deleteDashboardComments = async (dashboardCommentId?:number) => {
   setIsLoading(true);
   // GET allowed blob storage details
   fetch(
     process.env.REACT_APP_API_PATH +
     "/dashboardComment?dashboardCommentId="+dashboardCommentId,
     {
       method: "DELETE",
       headers: { authorization: await getAutorenewedToken() },
     }
   )
     .then(async (response) => {
       const data = await response.json();
       // check for error response
       if (!response.ok) {
         setNotify({
           isOpen: true,
           message: "Failed to Delete comment",
           type: "error",
         });  
         // get error message from body or default to response statusText
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
       }
       setDashboardComment(dashboardComment.filter(x => x.dashboardCommentId != dashboardCommentId));
       setFilterDashboardComment(filterdashboardComment.filter(x => x.dashboardCommentId != dashboardCommentId));
       setNotify({
         isOpen: true,
         message: "Comment Deleted Successfully",
         type: "success",
       });
       
       setIsLoading(false);
     })
     .catch((error) => {
       setIsLoading(false);
     });
 };
 const getCurrentUser = async () => {
   setIsLoading(true);
   // GET allowed blob storage details
   fetch(
     process.env.REACT_APP_API_PATH +
     "/user/current",
     {
       method: "GET",
       headers: { authorization: await getAutorenewedToken() },
     }
   )
     .then(async (response) => {
       const data = await response.json();
       // check for error response
       if (!response.ok) {
         // get error message from body or default to response statusText
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
       }
       setUser(data);
       setIsLoading(false);
     })
     .catch((error) => {
       setIsLoading(false);
     });
 };

 const getProject = async () => {
   setIsLoading(true);
   // GET allowed blob storage details
   fetch(
     process.env.REACT_APP_API_PATH +
     "/project",
     {
       method: "GET",
       headers: { authorization: await getAutorenewedToken() },
     }
   )
     .then(async (response) => {
       const data = await response.json();
       // check for error response
       if (!response.ok) {
         // get error message from body or default to response statusText
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
       }
       setProject(data);
       setIsLoading(false);
     })
     .catch((error) => {
       setIsLoading(false);
     });
 };
 const postDashboardVisualsComment = async () => {
   setIsLoading(true);
  
   // GET allowed blob storage details
   fetch(
     process.env.REACT_APP_API_PATH +
     "/dashboardComment",
     {
       method: "POST",
       headers: {
         Accept: 'application.json',
         'Content-Type': 'application/json',
         authorization: await getAutorenewedToken()
       },
       body: JSON.stringify({
         dashboardId: props.dashboardId,
         dashboardVisualId :visualId,
         reportDate:selectedDate,
         userId:user?.userId,
         projectId:projectId,
         comment:comment,
       })
     }
   )
     .then(async (response) => {
       // check for error response
       const data = await response.json();
       if (!response.ok) {
        mxPanelCommentHandler("Dashboard Comments Submit Comment");
         setNotify({
           isOpen: true,
           message: "Failed to submit comment",
           type: "error",
         });
         // get error message from body or default to response statusText
         return Promise.reject(response.statusText);
       }

       setDashboardComment([data, ...dashboardComment])
       //filterComment();
       if (filterVisualId == visualId || filterVisualId == null)
         setFilterDashboardComment([...filterdashboardComment, data])
       setOpenForm(false)
       resetDetail()
      
       setNotify({
         isOpen: true,
         message: "Comment Submitted Successfully",
         type: "success",
       });
       
       setIsLoading(false);
     })
     .catch((error) => {
       setIsLoading(false);
     });
 };
 const filterComment = async () =>  {
   var result = dashboardComment.filter(x => (filterProjectId == null || x.projectId == filterProjectId) 
                                         && (filterVisualId == null || x.dashboardVisualId == filterVisualId)
                                         && (filterDate == null || new Date(x.reportDate) >= new Date(filterDate.getFullYear(), filterDate.getMonth(), 1) && new Date(x.reportDate) <= new Date(filterDate.getFullYear(), filterDate.getMonth(), 1)))
   
   setFilterDashboardComment(result);
                                         
 }
 const commnetCount = async () => {
   var todayDate = new Date();
   var result = dashboardComment.filter(x => new Date(x.createdOn) >= new Date(todayDate.getFullYear(), todayDate.getMonth(), 1))
   setCommentCount(result.length);
 }
function resetDetail(){
 setSelectedDate(new Date());
 setComment('');
 setVisualId(undefined);
 setProjectId(null);
 setOpenForm(false);
}

 useEffect(() => {
   getCurrentUser();
   getProject();
 }, []);

 useEffect(() => {
   resetDetail()
   setVisuals([]);
   setFilterDate(null);
   setFilterVisualId(null);
   setDashboardComment([]);
   setFilterDashboardComment([]);
   getDashboardVisuals();
   getDashboardComments();
  
 }, [props.dashboardId]);

 useEffect(() => {
   if(filterDate != null)
   filterComment()
 }, [filterDate]);

 useEffect(() => {
   if(filterVisualId != null)
   filterComment()
 }, [filterVisualId]);

 useEffect(() => {
   if(filterProjectId != null)
   filterComment()
 }, [filterProjectId]);

 useEffect(() => {
   filterComment();
   commnetCount();
 }, [dashboardComment]);


 const input = () => (
   <>
     <Grid container>
       <Grid item xs={4} className={classes.pageContent}>
         <TextField
           select
           label="Select Project"
           variant="outlined"
           value={projectId ? projectId : ""}
           style={{ margin: "2%", width: "100%" }}
           //helperText="Please select visual"
           onChange={handleProjectChange}
         >
           {project.map((option) => (
             <MenuItem key={option.projectId} value={option.projectId}>
               {option.projectName}
             </MenuItem>
           ))}
         </TextField>
       </Grid>
       <Grid item xs={4} className={classes.pageContent}>
         <TextField
           select
           label="Select Visual"
           variant="outlined"
           value={visualId ? visualId : ""}
           style={{ margin: "2%", width: "100%" }}
           //helperText="Please select visual"
           onChange={handleChange}
         >
           {visuals.map((option) => (
             <MenuItem key={option.dashboardVisualsId} value={option.dashboardVisualsId}>
               {option.dashboardVisualsName}
             </MenuItem>
           ))}
         </TextField>
       </Grid>
       
       <Grid item xs={4} className={classes.pageContent}>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <KeyboardDatePicker
             maxDate={new Date()}
             disableToolbar
             variant="inline"
             format="MMM-yyyy"
             views={['year', 'month']}
             autoOk
             margin="normal"
             label="Reporting Month"
             value={selectedDate}
             style={{ margin: "2%", width: "100%" }}
             inputVariant="outlined"
             onChange={handleDateChange}
             //TextFieldComponent={TextFieldComponent}
           />
         </MuiPickersUtilsProvider>
       </Grid>
     </Grid>

     <TextField
       label="Comment"
       variant="outlined"
       multiline
       rows="5"
       value={comment}
       style={{ margin: "2%" }}
       onChange={(e) =>setComment(e.target.value )}
       margin="normal"
       inputProps={{ maxLength: 500 }}
     />
     <Grid container>
       <Grid container item xs={12} justifyContent="flex-end">
         <MuiButton style={{ marginBottom: "2%", marginRight: "2%", backgroundColor: "#e0e0e0" }} onClick={resetDetail} text="Cancel" type="submit" />
         <MuiButton style={{ marginBottom: "2%", marginRight: "2%" }} text="Submit" type="submit" 
         disabled={!(typeof comment!='undefined' && comment) || !(typeof visualId!='undefined' && visualId) || !(typeof selectedDate !='undefined' && selectedDate) || !(typeof projectId!='undefined' && projectId)} 
         onClick={postDashboardVisualsComment} />
       </Grid>
     </Grid>
   </>
 );
 const filter = () => (
   <>
    <Divider></Divider>
     <Grid container>
       <Grid item xs={4} className={classes.filter}>
       <Tooltip title="Filter comments" arrow>
         <IconButton style={{ fill: "greyColor" }} >
           <FilterIcon />
         </IconButton>
       </Tooltip>
         <TextField
           select
           label="Select Project"
           variant="standard"
           value={filterProjectId ? filterProjectId : ""}
           style={{ margin: "2%", width: "100%" }}
           //helperText="Please select visual"
           onChange={handleFilterProject}
         >
           {project.map((option) => (
             <MenuItem key={option.projectId} value={option.projectId}>
               {option.projectName}
             </MenuItem>
           ))}
         </TextField>
       </Grid>
       <Grid item xs={3} className={classes.filter}>
         <TextField
           select
           label="Select Visual"
           variant="standard"
           value={filterVisualId ? filterVisualId : ""}
           style={{ margin: "2%", width: "100%" }}
           //helperText="Please select visual"
           onChange={handleFilterChange}
         >
           {visuals.map((option) => (
             <MenuItem key={option.dashboardVisualsId} value={option.dashboardVisualsId}>
               {option.dashboardVisualsName}
             </MenuItem>
           ))}
         </TextField>
       </Grid>
       
       <Grid item xs={3} className={classes.filter}>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <KeyboardDatePicker
             maxDate={new Date()}
             disableToolbar
             variant="inline"
             format="MMM-yyyy"
             views={['year', 'month']}
             autoOk
             margin="normal"
             label="Reporting Month"
             value={filterDate}
             style={{ margin: "2%", width: "100%" }}
             inputVariant="standard"
             onChange={handleFilterDateChange}
           />
         </MuiPickersUtilsProvider>
       </Grid>
       <Grid item xs={2} className={classes.pageContent}>
         <MuiButton style={{ marginBottom: "2%", marginRight: "2%", backgroundColor: "#e0e0e0" }} onClick={clearFilter} text="Clear" type="submit" />
       </Grid>
     </Grid>
   </>
 );

 const addComment = () => (
   <>
     <Grid container>
       <Grid container item xs={12} justifyContent="flex-end">
          <MuiButton style={{ margin: "2%" }} text="Add Comment" onClick={() => {
                                                                                  mxPanelCommentHandler("Dashboard Comments Add Comment");
                                                                                  setOpenForm(true);
                                                                                }
                                                                          } type="submit" />
       </Grid>

     </Grid>

   </>
 );

 const list = (anchor: Anchor) => (
   <>
     
       {filterdashboardComment.map((option) => (
         <Card className={classes.card} key={option.dashboardCommentId} >
           <CardHeader
             avatar={<Avatar className={classes.avatar}>{option.user.userFirstName.substring(0,1)}{option.user.userLastName.substring(0,1)}</Avatar>}
              action={ option.user.userId == user?.userId && props.addComment ? (<><IconButton aria-label="settings" disabled={option.user.userId != user.userId} onClick={() => deleteDashboardComments(option.dashboardCommentId)}><DeleteIcon /></IconButton></>):""}
             title={<Typography color="textPrimary" className={classes.fontBold}>{option.user.userFirstName} {option.user.userLastName}</Typography>}
             subheader={format(new Date(option.createdOn), 'dd-MMM-yyyy')}/>
           <CardContent>
             <TableContainer component={Paper}>
               <Table className={classes.table}>
                 <TableHead>
                   <TableRow>
                     <TableCell>Project</TableCell>
                     <TableCell align="left">Visual</TableCell>
                     <TableCell align="left">Reporting Month</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                     <TableRow
                       key={option.dashboardCommentId}
                     >
                       <TableCell >
                       {option.project.projectName}
                       </TableCell>
                       <TableCell align="left">{option.dashboardVisual.dashboardVisualsName}</TableCell>
                       <TableCell align="left">{format(new Date(option.reportDate), 'MMM-yyyy')}</TableCell>
                     </TableRow>
                 </TableBody>
               </Table>
             </TableContainer>
            <Typography variant="body2" className={classes.dataUpload}>{option.comment}</Typography>
           </CardContent>
         </Card>

       ))}
   </>

 );

 return (
   <>
     <Tooltip title={'There are '+commentCount+ ' comments for this month ('+format(new Date(), 'MMM,yyyy')+ ') and ' + dashboardComment.length+ ' comments total'} arrow>
         <MaterialButton
           text={'Comments ('+ commentCount+')'}
           variant="outlined"
           color="default"
           startIcon={<CommentIcon />}
           className={classes.newButtonFirst}
           onClick={toggleDrawer(anchor, true)}
         />
     </Tooltip> 
     <SwipeableDrawer
       anchor={anchor}
       open={state[anchor]}
       onClose={toggleDrawer(anchor, false)}
       onOpen={toggleDrawer(anchor, true)}
     >
        <Notification notify={notify} setNotify={setNotify} />
       <div className={classes.pageHeader}>
         <Card className={classes.pageIcon}><CommentIcon /></Card>
         <div className={classes.pageTitle}>
           <Typography variant="h5" component="div">
             {props.dashboardName} Comments
           </Typography>
           <Typography variant="subtitle2" component="div">
             Add comment for dashboard
           </Typography>
         </div>
         <div className={classes.BackButton}>
           <Box>
             <IconButton style={{ fill: "greyColor" }}  onClick={toggleDrawer(anchor, false)}>
               <CloseIcon  />
             </IconButton>
           </Box>
         </div>
       </div>
       {filter()}  

       <Divider style={{marginBottom:theme.spacing(2)}}></Divider>
       <Box
       sx={{ width: 250 }}
       className={classes.comment}
       role="presentation"
       >{list(anchor)}</Box>
       <Divider></Divider>
        { props.addComment && (openForm ? input() : addComment())}

     </SwipeableDrawer>
    
   </>

 );
}
