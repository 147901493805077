import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "src/components/utils/Page";
import PageHeader from "src/components/utils/PageHeader";
import MaterialButton from "src/components/utils/Button";
import DataTable from "../../utils/DataTable";
import Notification from "src/components/utils/Notification";
import Searchbar from "src/components/utils/Searchbar";
import { getAutorenewedToken } from "../../auth/msalUtils";
import { getAdminIconName } from "../../utils/RouterStorage";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "25%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    fontSize: "14px",
  },
  iconButton: {
    color: theme.palette.grey[500],
  },
}));

const UpdateUserSecurity = () => {
  const classes = useStyles();

  const uniqueId = "userId";
  const tableName = "User Security Group";
  const pageHeader = "Update User Security Group";
  const pageSubtitle = "Add, update or remove security groups among users";

  const [records, setRecords] = useState(null);
  const [recordsSelectedList, setRecordsSelectedList] = useState([]);
  const [securityRecords, setSecurityRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //SNACKBAR NOTIFICATIONS
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const activeHeaders = [
    {
      id: 0,
      value: "id",
      label: "ID",
      active: false,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 1,
      value: "arrow",
      label: "",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 2,
      value: "userId",
      label: "User ID",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 3,
      value: "userName",
      label: "Username",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 4,
      value: "userFirstName",
      label: "First Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
    {
      id: 5,
      value: "userLastName",
      label: "Last Name",
      active: true,
      checkbox: false,
      disableSorting: false,
    },
  ].filter((header) => header.active);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    DataTable(records, activeHeaders, filterFn);

  let updatedUserGroup = {};

  // GET request using fetch with error handling
  const fetchData = async (isInit = false) => {
    // Get data from combined API
    fetch(process.env.REACT_APP_API_PATH + "/usersecurity/userscombined", {
      method: "GET",
      headers: { authorization: await getAutorenewedToken() },
    })
      .then(async (response) => {
        // let mixedData = [];
        const activeData = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (activeData && activeData.message) || response.statusText;
          return Promise.reject(error);
        }
        setRecords(activeData.users);
        if (isInit) setRecordsSelectedList(activeData.users.map((x) => false));
        setSecurityRecords(activeData.securityGroups);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const contentComparer = (otherArray) => {
    return (current) => {
      return (
        otherArray.filter(function (other) {
          return other.securityGroupId === current.securityGroupId;
        }).length === 0
      );
    };
  };

  const addGroup = (inGroupList, apiToken) => {
    fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: apiToken },
      body: JSON.stringify(inGroupList),
    })
      .then((ret) => {
        const isJson = ret.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && ret.json();

        // check for error response
        if (ret.ok) {
          fetchData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || ret.status;
          console.error("Error happened while add user security", error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("An error while calling API to add user security!", error);
      });
  };

  const deleteGroup = (inGroupList, apiToken) => {
    fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
      method: "DELETE",
      headers: { "Content-Type": "application/json", authorization: apiToken },
      body: JSON.stringify(inGroupList),
    })
      .then((ret) => {
        const isJson = ret.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && ret.json();

        // check for error response
        if (ret.ok) {
          fetchData();
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || ret.status;
          console.error("Error happened while delete user security", error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("An error while calling API to delete user security!", error);
      });
  };

  const updateGroup = (inNewList, inDelList, apiToken) => {
    fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
      method: "DELETE",
      headers: { "Content-Type": "application/json", authorization: apiToken },
      body: JSON.stringify(inDelList),
    })
      .then((ret) => {
        const isJson = ret.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && ret.json();

        // check for error response
        if (ret.ok) {
          fetch(process.env.REACT_APP_API_PATH + "/usersecurity", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: apiToken,
            },
            body: JSON.stringify(inNewList),
          })
            .then((res) => {
              const isJson = res.headers
                .get("content-type")
                ?.includes("application/json");
              const data = isJson && res.json();

              // check for error response
              if (res.ok) {
                fetchData();
                setNotify({
                  isOpen: true,
                  message: "Submitted Successfully",
                  type: "success",
                });
              } else {
                // get error message from body or default to response status
                const error = (data && data.message) || ret.status;
                console.error("Error happened while add user security", error);
                return Promise.reject(error);
              }
            })
            .catch((error) => {
              console.error("An error while calling API to add user security!", error);
            });
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || ret.status;
          console.error("Error happened while delete user security", error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("An error while calling API to delete user security!", error);
      });
  };

  const updateSecurityGroup = async () => {
    // Get the add and delete group list
    let newSecurityGrp = [],
      delSecurityGrp = [];
    records.forEach((item) => {
      if (updatedUserGroup[item.userId]) {
        delSecurityGrp = delSecurityGrp.concat(
          item.userSecurityGroups
            .filter(contentComparer(updatedUserGroup[item.userId]))
            .map((x) => {
              return {
                userId: x.userId,
                securityGroupId: x.securityGroupId,
                userSecurityGroupId: x.userSecurityGroupId,
              };
            })
        );
        newSecurityGrp = newSecurityGrp.concat(
          updatedUserGroup[item.userId]
            .filter(contentComparer(item.userSecurityGroups))
            .map((y) => {
              return {
                userId: y.userId,
                securityGroupId: y.securityGroupId,
                userSecurityGroupId: 0,
              };
            })
        );
      }
    });
    // Run API to reflect the change
    const apiToken = await getAutorenewedToken();
    if (newSecurityGrp.length > 0 && delSecurityGrp.length > 0) {
      updateGroup(newSecurityGrp, delSecurityGrp, apiToken);
    } else if (newSecurityGrp.length > 0) {
      addGroup(newSecurityGrp, apiToken);
    } else if (delSecurityGrp.length > 0) {
      deleteGroup(delSecurityGrp, apiToken);
    } else {
      setNotify({
        isOpen: true,
        message: "No updated user security group",
        type: "success",
      });
    }
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    fetchData(true);
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((data) =>
            Object.values(data).some((val) =>
              String(val)
                .toLowerCase()
                .includes(target.value.toString().toLowerCase())
            )
          );
        }
      },
    });
  };

  function CollapseTableRow(props) {
    const row = props.value;
    const currentUserID = props.rowId;
    const [open, setOpen] = useState(recordsSelectedList[props.selectedIdx]);
    const [selected, setSelected] = useState([]);

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        setSelected(securityRecords);
        return;
      }
      setSelected([]);
    };

    const handleClick = (gRow) => {
      const selectedIndex = selected.indexOf(gRow);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, gRow);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);

      updatedUserGroup[currentUserID] = newSelected.map((item) => {
        return { userId: currentUserID, securityGroupId: item.securityGroupId };
      });
    };

    const isItemSelected = (gRow) => selected.indexOf(gRow) !== -1;

    const toggleSecTable = (inFlag) => {
      setOpen(!inFlag);
      recordsSelectedList[props.selectedIdx] = !inFlag;
    };

    useEffect(() => {
      let newSelected = [];
      if (row && row.userSecurityGroups) {
        row.userSecurityGroups.forEach((item) => {
          newSelected.push(
            securityRecords.filter(
              (x) => x.securityGroupId === item.securityGroupId
            )[0]
          );
        });
        setSelected(newSelected);
      }
    }, [row]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <React.Fragment>
        <TableRow hover>
          {activeHeaders.map((col) =>
            col.value === "arrow" ? (
              <TableCell key={col.id} onClick={() => toggleSecTable(open)}>
                <IconButton aria-label="expand row">
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell key={col.id} onClick={() => toggleSecTable(open)}>
                {row[col.value]}
              </TableCell>
            )
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Security Group
                </Typography>
                <Table size="small" aria-label="securitygroup">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected && selected.length < securityRecords.length
                          }
                          checked={
                            selected &&
                            selected.length === securityRecords.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all groups" }}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {securityRecords.map((groupRow) => (
                      <TableRow
                        key={groupRow.securityGroupId}
                        onClick={() => handleClick(groupRow)}
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={isItemSelected(groupRow)}
                            inputProps={{
                              "aria-labelledby": groupRow.securityGroupName,
                            }}
                          />
                        </TableCell>
                        <TableCell>{groupRow.securityGroupName}</TableCell>
                        <TableCell>
                          {groupRow.securityGroupDescription}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Page title={pageHeader}>
      <PageHeader
        title={pageHeader}
        subtitle={pageSubtitle}
        icon={<FontAwesomeIcon icon={["fas", getAdminIconName()]} />}
        backButton={true}
      />

      <Paper className={classes.pageContent}>
        <Toolbar>
          <Searchbar title={"Search"} handleSearch={handleSearch} />

          <MaterialButton
            text="Save"
            variant="outlined"
            startIcon={<SaveIcon />}
            className={classes.newButton}
            onClick={() => {
              updateSecurityGroup();
            }}
          />
        </Toolbar>

        {records !== null && securityRecords !== null ? (
          <>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row, idx) => (
                  <CollapseTableRow
                    key={row[uniqueId]}
                    value={row}
                    rowId={row[uniqueId]}
                    selectedIdx={idx}
                  ></CollapseTableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </>
        ) : (
          <TblContainer>
            <TblHead />
          </TblContainer>
        )}
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Page>
  );
};

export default UpdateUserSecurity;
